import { render, staticRenderFns } from "./ActorEditSocial.vue?vue&type=template&id=0dccde04&scoped=true&"
import script from "./ActorEditSocial.vue?vue&type=script&lang=js&"
export * from "./ActorEditSocial.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dccde04",
  null
  
)

export default component.exports